import config from 'config';
import http from './http';
import { Agenda, ServerResponse } from './types';

export const get = async (accessToken: string): Promise<Agenda[] | undefined> => {
    const url = encodeURI(`${config.serverUrl}/agenda`);
    console.log('AGENDA - GET - URL', url);
    const response: ServerResponse<Agenda> = await http.get(url, {
        headers: { Authorization: `Bearer ${accessToken}` }
    });
    if (!response.data.code) throw response.data;
    return response.data.objects;
};

export const create = async (accessToken: string, agenda: Omit<Agenda, 'id'>): Promise<Agenda | undefined> => {
    const url = encodeURI(`${config.serverUrl}/agenda/create`);
    console.log('AGENDA - CREATE - URL', url);
    const response: ServerResponse<Agenda> = await http.post(url, agenda, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    });
    if (!response.data.code) throw response.data;
    return response.data.object;
};

export const update = async (accessToken: string, agenda: Pick<Agenda, 'id'> & Partial<Agenda>): Promise<Agenda | undefined> => {
    const { id, ...agendaData } = agenda;
    const url = encodeURI(`${config.serverUrl}/agenda/${id}/update`);
    console.log('AGENDA - UPDATE - URL', url);
    const response: ServerResponse<Agenda> = await http.post(url, agendaData, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        }
    });
    if (!response.data.code) throw response.data;
    return response.data.object;
};
