import { Box, Toolbar, useMediaQuery } from '@mui/material';
// material-ui
import { useTheme } from '@mui/material/styles';
import { get } from 'apis/agenda';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { appRoutes } from 'routes';
import { Route } from 'routes/RoutesTypes';
import { StateType } from 'store/reducers';
// types
import { openDrawer } from 'store/reducers/menu';
// project import
import InviteDialog from 'components/invitation/InviteDialog';
import AppProvider from './AppContext/AppProvider';
import Drawer from './Drawer';
import { reduceForNavigation } from './Drawer/DrawerContent/Navigation';
import Header from './Header';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout: React.FC = (): JSX.Element => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

    const { accessToken } = useSelector((state: StateType) => state.auth);
    const { drawerOpen } = useSelector((state: StateType) => state.menu);

    const [open, setOpen] = useState(drawerOpen);
    const [routes, setRoutes] = useState<Route[]>([]);

    useEffect(() => {
        setOpen(!matchDownLG);
        dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }, [dispatch, matchDownLG]);

    useEffect(() => {
        if (open !== drawerOpen) {
            setOpen(drawerOpen);
        }
    }, [drawerOpen, open]);

    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    useEffect(() => {
        if (accessToken) {
            void (async () => {
                const agende = accessToken ? await get(accessToken) : undefined;
                const agenda = agende?.length ? agende[0] : undefined;
                const newRoutes = reduceForNavigation(appRoutes(t, agenda), undefined, accessToken, agenda);
                setRoutes(newRoutes);
            })();
        }
    }, [accessToken, t]);

    return (
        <AppProvider>
            <Box
                sx={{
                    display: 'flex',
                    minHeight: '100vh',
                    width: '100%',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: `url(${process.env.PUBLIC_URL}/domains/${document.location.hostname}/background.jpg)`,
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        filter: 'saturate(0.5) opacity(0.5)',
                        zIndex: -1,
                        opacity: 0.8
                    }
                }}
            >
                <Header open={open} handleDrawerToggle={handleDrawerToggle} />
                <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
                <Box component="main" sx={{ width: '100%', flexGrow: 1, p: { xs: 2, sm: 3 } }}>
                    <Toolbar sx={{ opacity: 0 }} />
                    <Breadcrumbs navigation={routes} title titleBottom card={false} divider={false} />
                    <Outlet />
                </Box>
            </Box>
            <InviteDialog />
        </AppProvider>
    );
};

export default MainLayout;
