import { Chip, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { get } from 'apis/agenda';
import Logo from 'components/Logo';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { StateType } from 'store/reducers';
import { CustomTheme } from 'themes';
import { addDays, addMonths, formatDate, isAfter, isBefore } from 'utils/dates';

// ==============================|| DRAWER HEADER ||============================== //

export interface DrawerHeaderProps {
    open?: boolean;
}

const DrawerHeader: React.FC<DrawerHeaderProps> = ({ open }): JSX.Element => {
    const { accessToken } = useSelector((state: StateType) => state.auth);
    const theme: CustomTheme = useTheme();
    const { t } = useTranslation();

    const [expiryDate, setExpiryDate] = useState<Date | undefined>();
    const [color, setColor] = useState<'error' | 'success' | 'warning' | undefined>();

    useEffect(() => {
        if (accessToken) {
            void (async () => {
                const agende = await get(accessToken);
                setExpiryDate(agende?.length && agende[0].expiryDate ? new Date(agende[0].expiryDate) : undefined);
            })();
        } else setExpiryDate(undefined);
    }, [accessToken]);

    useEffect(() => {
        if (expiryDate) {
            setColor(isBefore(expiryDate) ? 'error' : !isAfter(expiryDate, addMonths(new Date(), 1)) ? 'warning' : 'success');
        } else {
            setColor(undefined);
        }
    }, [expiryDate, theme]);

    return (
        <Stack spacing={1} pt={1} alignItems="center">
            <Logo sx={{ width: '100%' }} />
            {!!expiryDate && (
                <Chip
                    label={
                        isBefore(expiryDate)
                            ? t('header_license_expired', { expiryDate: formatDate(addDays(expiryDate, -1), 'DD/MM/YYYY') }) // La data da visualizzare è l'ultimo giorno prima della scadenza della licenza
                            : !isAfter(expiryDate, addMonths(new Date(), 1))
                            ? t('header_license_expiring', { expiryDate: formatDate(addDays(expiryDate, -1), 'DD/MM/YYYY') })
                            : t('header_license_valid', { expiryDate: formatDate(addDays(expiryDate, -1), 'DD/MM/YYYY') })
                    }
                    color={color}
                    size="small"
                    sx={{ height: 16, '& .MuiChip-label': { fontSize: '0.625rem' } }}
                />
            )}
        </Stack>
    );
};

export default DrawerHeader;
