import { Components, alpha } from '@mui/material/styles';
import { CustomTheme } from 'themes';

// ==============================|| OVERRIDES - BUTTON ||============================== //

const Button = (theme: CustomTheme): Components<CustomTheme> => {
    return {
        MuiButton: {
            defaultProps: {
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    fontWeight: 400
                },
                contained: {
                    '&.Mui-disabled': {
                        backgroundColor: alpha(theme.palette.grey[200], 0.25)
                    }
                },
                outlined: {
                    backgroundColor: alpha(theme.palette.background.paper, 0.5),
                    backdropFilter: 'blur(20px)',
                    '&.Mui-disabled': {
                        backgroundColor: alpha(theme.palette.grey[200], 0.25)
                    },
                    '&.MuiButton-outlinedPrimary:not(.Mui-disabled):hover': {
                        backgroundColor: alpha(theme.palette.primary.main, 0.25)
                    },
                    '&.MuiButton-colorSecondary:not(.Mui-disabled):hover': {
                        backgroundColor: alpha(theme.palette.secondary.main, 0.25)
                    },
                    '&.MuiButton-colorSuccess:not(.Mui-disabled):hover': {
                        backgroundColor: alpha(theme.palette.success.main, 0.25)
                    },
                    '&.MuiButton-colorWarning:not(.Mui-disabled):hover': {
                        backgroundColor: alpha(theme.palette.warning.main, 0.25)
                    }
                }
            }
        }
    };
};

export default Button;
