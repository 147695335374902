import { Components, Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - PAPER ||============================== //

const Paper = (): Components<Theme> => {
    return {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backdropFilter: 'blur(20px)'
                }
            }
        }
    };
};

export default Paper;
