import { Box } from '@mui/material';
import { get } from 'apis/agenda';
import { Agenda } from 'apis/types';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { appRoutes } from 'routes';
import { Route } from 'routes/RoutesTypes';
import { StateType } from 'store/reducers';
import { isAfter } from 'utils/dates';
import NavGroup from './NavGroup';
import NavItem from './NavItem';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

export const reduceForNavigation = (routes: Route[], path?: string, accessToken?: string, agenda?: Agenda) => {
    const isLicenseValid = agenda?.expiryDate && isAfter(new Date(agenda.expiryDate));
    return routes.reduce((accumulator, element) => {
        const route = Object.assign({}, element) as Route;
        if (
            ((accessToken && route.requireAuth !== false) || (!accessToken && !route.requireAuth)) &&
            ((isLicenseValid && route.requireLicense !== false) || (!isLicenseValid && !route.requireLicense))
        ) {
            if (path && !route.external) {
                route.path = `${path}${route.path || ''}`;
            }
            if (route.children?.length) {
                route.children = reduceForNavigation(route.children, route.path, accessToken, agenda);
            }
            if (route.title) {
                if ((route.path && route.element) || route.children?.length) {
                    accumulator.push(route);
                }
            } else if (route.children?.length) {
                accumulator.push(...route.children);
            }
        }
        return accumulator;
    }, [] as Route[]);
};

const Navigation: React.FC = () => {
    const [t] = useTranslation();

    const { accessToken } = useSelector((state: StateType) => state.auth);
    const [routes, setRoutes] = useState<Route[]>([]);

    useEffect(() => {
        if (accessToken) {
            void (async () => {
                const agende = accessToken ? await get(accessToken) : undefined;
                const agenda = agende?.length ? agende[0] : undefined;
                const newRoutes = reduceForNavigation(appRoutes(t, agenda), undefined, accessToken, agenda);
                setRoutes(newRoutes);
            })();
        }
    }, [accessToken, t]);

    const navGroups = useMemo(() => {
        return routes.map((route) =>
            route.title && !route.element ? (
                <NavGroup key={route.title} group={route} />
            ) : (
                <NavItem key={route.id || route.title} route={route} />
            )
        );
    }, [routes]);

    return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
