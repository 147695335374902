import { Outlet } from 'react-router-dom';

// ==============================|| MINIMAL LAYOUT ||============================== //

const MinimalLayout: React.FC = (): JSX.Element => (
    <div
        style={{
            background: `url(${process.env.PUBLIC_URL}/domains/${document.location.hostname}/background.jpg)`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        }}
    >
        <Outlet />
    </div>
);

export default MinimalLayout;
