import {
    BookOutlined,
    CalendarOutlined,
    DashboardOutlined,
    EuroCircleOutlined,
    FileTextOutlined,
    HourglassOutlined,
    LoginOutlined,
    PhoneOutlined,
    UnorderedListOutlined,
    UserOutlined
} from '@ant-design/icons';
import { get } from 'apis/agenda';
import { Agenda } from 'apis/types';
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import { WhitelabelledRenew } from 'layout/Whitelabelling/WhitelabelledRenew';
import { lazy, useEffect, useState } from 'react';
import { TFunction, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
import { StateType } from 'store/reducers';
import { isAfter } from 'utils/dates';
import { Route } from './RoutesTypes';

// ==============================|| ROUTING RENDER ||============================== //

// render - login
const AuthRegister = Loadable(lazy(() => import('pages/authentication/Register')));
const AuthActivate = Loadable(lazy(() => import('pages/authentication/Activate')));
const AuthLogin = Loadable(lazy(() => import('pages/authentication/Login')));
const AuthReset = Loadable(lazy(() => import('pages/authentication/Reset')));

// render - utilities
const Dashboard = Loadable(lazy(() => import('pages/beetalk/Dashboard')));
const Profile = Loadable(lazy(() => import('pages/beetalk/Profile')));
const Availabilities = Loadable(lazy(() => import('pages/beetalk/Availabilities')));
const Calendar = Loadable(lazy(() => import('pages/beetalk/Calendar')));
const CDR = Loadable(lazy(() => import('pages/beetalk/CDR')));
const IVR = Loadable(lazy(() => import('pages/beetalk/IVR')));
const Transcriptions = Loadable(lazy(() => import('pages/beetalk/Transcriptions')));
const ExpiredLicense = Loadable(lazy(() => import('pages/beetalk/ExpiredLicense')));

export const appRoutes = (t: TFunction<'translation', undefined>, agenda?: Agenda): Route[] => {
    return [
        {
            requireAuth: true,
            children: [
                {
                    requireLicense: true,
                    element: <Navigate to="/beetalk/dashboard" />,
                    path: '*'
                },
                {
                    requireLicense: true,
                    element: <Navigate to="/beetalk/dashboard" />,
                    path: '/'
                },
                {
                    requireLicense: false,
                    element: <Navigate to="/license/renew" />,
                    path: '/beetalk/*'
                },
                {
                    requireLicense: false,
                    element: <Navigate to="/license/renew" />,
                    path: '/'
                },
                {
                    path: '/',
                    element: <MainLayout />,
                    children: [
                        {
                            id: 'profile',
                            element: <Profile />,
                            icon: UserOutlined,
                            path: 'profile'
                        }
                    ]
                }
            ]
        },
        {
            requireAuth: false,
            children: [
                {
                    element: <Navigate to="/login" />,
                    path: '*'
                },
                {
                    element: <Navigate to="/login" />,
                    path: '/'
                }
            ]
        },
        {
            title: t('nav_group_authentication'),
            requireAuth: false,
            children: [
                {
                    path: '/',
                    element: <MinimalLayout />,
                    children: [
                        {
                            id: 'register',
                            element: <AuthRegister />,
                            path: 'register',
                            requireAuth: false
                        },
                        {
                            id: 'activate',
                            element: <AuthActivate />,
                            path: 'activate',
                            requireAuth: false
                        },
                        {
                            id: 'login',
                            title: t('nav_item_login'),
                            element: <AuthLogin />,
                            icon: LoginOutlined,
                            path: 'login'
                        },
                        {
                            id: 'reset',
                            element: <AuthReset />,
                            path: 'reset'
                        }
                    ]
                }
            ]
        },
        {
            title: t('nav_group_beetalk'),
            requireAuth: true,
            requireLicense: true,
            children: [
                {
                    path: '/beetalk/',
                    element: <MainLayout />,
                    children: [
                        {
                            id: 'dashboard',
                            title: t('nav_item_dashboard'),
                            element: <Dashboard />,
                            icon: DashboardOutlined,
                            path: 'dashboard'
                        },
                        {
                            id: 'availabilities',
                            title: t('nav_item_availabilities'),
                            element: <Availabilities />,
                            icon: BookOutlined,
                            path: 'availabilities'
                        },
                        {
                            id: 'bookings',
                            title: t('nav_item_bookings'),
                            element: <Calendar />,
                            icon: CalendarOutlined,
                            path: 'bookings'
                        },
                        {
                            id: 'ivr',
                            title: t('nav_item_ivr'),
                            element: <IVR />,
                            icon: PhoneOutlined,
                            path: 'ivr'
                        },
                        {
                            id: 'cdr',
                            title: t('cdr_title'),
                            element: <CDR />,
                            icon: UnorderedListOutlined,
                            path: 'cdr'
                        },
                        {
                            id: 'voicemail',
                            title: t('transcription_title'),
                            element: <Transcriptions />,
                            icon: FileTextOutlined,
                            path: 'voicemail'
                        }
                    ]
                }
            ]
        },
        ...[
            agenda && !agenda.hideRenewPage
                ? {
                      title: t('nav_group_payments'),
                      requireAuth: true,
                      children: [
                          {
                              path: '/license/',
                              element: <MainLayout />,
                              children: [
                                  {
                                      id: 'renew',
                                      title: t('nav_item_renew_license_title'),
                                      element: <WhitelabelledRenew />,
                                      icon: EuroCircleOutlined,
                                      path: 'renew'
                                  }
                              ]
                          }
                      ]
                  }
                : agenda
                ? {
                      requireAuth: true,
                      requireLicense: false,
                      path: '/license/',
                      element: <MainLayout />,
                      children: [
                          {
                              id: 'expired',
                              title: t('nav_item_license_expired'),
                              element: <ExpiredLicense />,
                              icon: HourglassOutlined,
                              path: 'expired'
                          }
                      ]
                  }
                : {}
        ],
        // Redirect finali di fallback nel caso il path non sia matchato da niente
        {
            requireAuth: true,
            requireLicense: false,
            element: <Navigate to={agenda && !agenda.hideRenewPage ? '/license/renew' : '/license/expired'} />,
            path: '*'
        }
    ];
};

const reduceRoutes = (routes: Route[], accessToken?: string, agenda?: Agenda) => {
    return routes.reduce((accumulator, element) => {
        const route = Object.assign({}, element) as Route;
        const isLicenseValid = agenda?.expiryDate && isAfter(new Date(agenda.expiryDate));
        if (
            ((accessToken && route.requireAuth !== false) || (!accessToken && !route.requireAuth)) &&
            ((isLicenseValid && route.requireLicense !== false) || (!isLicenseValid && !route.requireLicense))
        ) {
            delete route.requireAuth;
            delete route.requireLicense;
            if (route.children?.length) {
                route.children = reduceRoutes(route.children, accessToken, agenda);
            }
            if (route.path && !route.external) {
                accumulator.push(route);
            } else if (route.children?.length) {
                accumulator.push(...route.children);
            }
        }
        return accumulator;
    }, [] as Route[]);
};

const ThemeRoutes: React.FC = (): React.ReactElement | null => {
    const [t] = useTranslation();
    const { accessToken } = useSelector((state: StateType) => state.auth);
    const [routes, setRoutes] = useState<Route[]>([]);

    useEffect(() => {
        void (async () => {
            const agende = accessToken ? await get(accessToken) : undefined;
            const agenda = agende?.length ? agende[0] : undefined;
            const newRoutes = reduceRoutes(appRoutes(t, agenda), accessToken, agenda);
            setRoutes(newRoutes);
        })();
    }, [accessToken, t]);

    return useRoutes(routes);
};

export default ThemeRoutes;
