// material-ui
import { alpha, Components } from '@mui/material/styles';
import { CustomTheme } from 'themes';

// ==============================|| OVERRIDES - OUTLINED INPUT ||============================== //

const InputBase = (theme: CustomTheme): Components<CustomTheme> => {
    return {
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: alpha(theme.palette.background.paper, 1)
                }
            }
        }
    };
};

export default InputBase;
