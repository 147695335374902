import { Alert, AlertTitle, Grid, Typography } from '@mui/material';
import { get } from 'apis/agenda';
import { getAll as getAllPrices, Price } from 'apis/price';
import { Agenda } from 'apis/types';
import ErrorDialog from 'components/Dialog/ErrorDialog';
import MessageDialog from 'components/Dialog/MessageDialog';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { StateType } from 'store/reducers';
import { addDays, formatDate } from 'utils/dates';
import { getErrorMessage } from 'utils/errors';
import { localizePrice } from 'utils/numbers';
import PaymentOption from './PaymentOption';

const Renew: React.FC = (): JSX.Element => {
    const { t } = useTranslation();
    const { accessToken } = useSelector((state: StateType) => state.auth);

    const [searchParams, setSearchParams] = useSearchParams();

    const [error, setError] = useState<string | undefined>();
    const [agenda, setAgenda] = useState<Agenda | undefined>();
    const [payment, setPayment] = useState(
        searchParams.has('AMOUNT') && !isNaN(parseFloat(searchParams.get('AMOUNT') || ''))
            ? {
                  amount: parseInt(searchParams.get('AMOUNT') as string) / 100
              }
            : undefined
    );

    const [price, setPrice] = useState<Price | undefined>();

    const baseMonthPrice = useMemo(() => price?.base.find((price) => price.months === 1), [price]);
    const baseHalfYearPrice = useMemo(() => price?.base.find((price) => price.months === 6), [price]);
    const baseYearPrice = useMemo(() => price?.base.find((price) => price.months === 12), [price]);

    const discountMonthPrice = useMemo(() => price?.discount?.find((price) => price.months === 1), [price]);
    const discountHalfYearPrice = useMemo(() => price?.discount?.find((price) => price.months === 6), [price]);
    const discountYearPrice = useMemo(() => price?.discount?.find((price) => price.months === 12), [price]);

    const vattedMonthPrice = useMemo(() => price?.vatted.find((price) => price.months === 1), [price]);
    const vattedHalfYearPrice = useMemo(() => price?.vatted.find((price) => price.months === 6), [price]);
    const vattedYearPrice = useMemo(() => price?.vatted.find((price) => price.months === 12), [price]);

    const paymentOptionsWidth = useMemo(() => {
        const visiblePrices = [baseMonthPrice, baseHalfYearPrice, baseYearPrice].filter((basePrice) => basePrice !== undefined).length;
        return Math.floor(12 / visiblePrices);
    }, [baseHalfYearPrice, baseMonthPrice, baseYearPrice]);

    useEffect(() => {
        if (payment) {
            Array.from(searchParams.entries()).forEach((param) => searchParams.delete(param[0]));
            setSearchParams(searchParams);
        }
    }, []);

    useEffect(() => {
        if (accessToken) {
            get(accessToken)
                .then((agende) => {
                    if (agende?.length) {
                        setAgenda(agende[0]);
                        getAllPrices(accessToken, agende[0].id)
                            .then((price) => {
                                setPrice(price);
                            })
                            .catch((err) => setError(getErrorMessage(err)));
                    }
                })
                .catch((err) => setError(getErrorMessage(err)));
        }
    }, [accessToken]);

    const licenseWarning = useMemo(
        () =>
            agenda?.expiryDate &&
            new Date(agenda.expiryDate) < new Date() && (
                <Grid item xs={12}>
                    <Alert severity="error">
                        <AlertTitle>{t('warning')}</AlertTitle>
                        {t('license_renew_warning_expiration_message', {
                            expiryDate: formatDate(addDays(agenda.expiryDate, -1), 'DD/MM/YYYY') // La data da mostrare è quella dell'ultimo giorno di validità incluso
                        })}
                    </Alert>
                </Grid>
            ),
        [agenda, t]
    );

    return (
        <>
            <Grid container rowSpacing={4.5} columnSpacing={2.75} sx={{ height: '80%', placeContent: 'flex-start' }}>
                {licenseWarning}
                <Grid item xs={12} alignContent={'end'}>
                    <Typography variant="h1" textAlign="center">
                        {t('license_renew_title')}
                    </Typography>
                </Grid>
                {baseMonthPrice && (
                    <Grid item sm={paymentOptionsWidth} xs={12} alignContent={'end'}>
                        <PaymentOption
                            price={baseMonthPrice?.price ?? 0}
                            discountedPrice={discountMonthPrice?.price}
                            vattedPrice={vattedMonthPrice?.price ?? 0}
                            vat={discountMonthPrice?.vat ?? 0}
                            description=""
                            months={1}
                            setError={setError}
                            priceList={price as Price}
                        />
                    </Grid>
                )}
                {baseHalfYearPrice && (
                    <>
                        <Grid item sm={paymentOptionsWidth} xs={12} alignContent={'end'}>
                            <PaymentOption
                                price={baseHalfYearPrice?.price ?? 0}
                                discountedPrice={discountHalfYearPrice?.price}
                                vattedPrice={vattedHalfYearPrice?.price ?? 0}
                                vat={discountHalfYearPrice?.vat ?? 0}
                                description=""
                                months={6}
                                setError={setError}
                                priceList={price as Price}
                            />
                        </Grid>
                    </>
                )}
                {baseYearPrice && (
                    <Grid item sm={paymentOptionsWidth} xs={12} alignContent={'end'}>
                        <PaymentOption
                            price={baseYearPrice?.price ?? 0}
                            discountedPrice={discountYearPrice?.price}
                            vattedPrice={vattedYearPrice?.price ?? 0}
                            vat={discountYearPrice?.vat ?? 0}
                            description=""
                            months={12}
                            setError={setError}
                            priceList={price as Price}
                        />
                    </Grid>
                )}
            </Grid>
            <MessageDialog
                id="payment-success"
                title={t('license_renew_payment_success_title')}
                open={!!payment}
                onContinue={() => setPayment(undefined)}
            >
                <Typography variant="h6">
                    {t('license_renew_payment_success_message', { amount: localizePrice(payment?.amount || 0) })}
                </Typography>
            </MessageDialog>
            {error && <ErrorDialog open={!!error} error={error} onContinue={() => setError(undefined)} />}
        </>
    );
};

export default Renew;
