import { Components, Theme, alpha } from '@mui/material/styles';
import { CustomTheme } from 'themes';

// ==============================|| OVERRIDES - PAPER ||============================== //

const Dialog = (theme: CustomTheme): Components<Theme> => {
    return {
        MuiDialog: {
            styleOverrides: {
                container: {
                    '& .MuiPaper-root': {
                        backgroundColor: alpha(theme.palette.background.paper, 1)
                    }
                }
            }
        }
    };
};

export default Dialog;
