import { Components, alpha } from '@mui/material/styles';
import { CustomTheme } from 'themes';

// ==============================|| OVERRIDES - BUTTON GROUP ||============================== //

const ToggleButtonGroup = (theme: CustomTheme): Components<CustomTheme> => {
    return {
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    backgroundColor: alpha(theme.palette.background.paper, 0.5),
                    backdropFilter: 'blur(20px)'
                }
            }
        }
    };
};

export default ToggleButtonGroup;
