import moment from 'moment';

export const safeParse = (dateStr?: string | null) => {
    let result = undefined;
    try {
        if (dateStr) {
            result = moment(dateStr).toDate();
        }
    } catch (error) {
        console.error(error);
    }
    return result;
};

export const parseFromTime = (time: string, date?: Date) => {
    return moment(`${date ? formatDate(date, 'YYYY-MM-DD') : '1970-01-01'} ${time}`).toDate();
};

export const parseFromTimeTZ = (time: string, date?: Date) => {
    return moment(`${date ? formatDate(date, 'YYYY-MM-DD') : '1970-01-01'}T${time}.000Z`).toDate();
};

export const formatDate = (date: Date, formatString: string): string => {
    return moment(date).format(formatString);
};

export const formatUTCDate = (date: Date, formatString: string): string => {
    return moment(date).utc().format(formatString);
};

export const isSameDate = (date1: Date, date2?: Date): boolean => {
    date2 = date2 ? new Date(date2) : new Date();
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
};

export const isSameWeek = (date1: Date, date2?: Date): boolean => {
    date2 = date2 ? new Date(date2) : new Date();
    return yearWeekNumber(date1) === yearWeekNumber(date2);
};

export const isSameMonth = (date1: Date, date2?: Date): boolean => {
    date2 = date2 ? new Date(date2) : new Date();
    return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
};

export const isBefore = (date1: Date, date2?: Date): boolean => {
    return date1.getTime() < (date2 ? date2.getTime() : new Date().getTime());
};

export const isAfter = (date1: Date, date2?: Date): boolean => {
    return date1.getTime() > (date2 ? date2.getTime() : new Date().getTime());
};

export const dayOfWeek = (num: number): string => {
    switch (num) {
        case 1:
            return 'monday_one';
        case 2:
            return 'tuesday_one';
        case 3:
            return 'wednesday_one';
        case 4:
            return 'thursday_one';
        case 5:
            return 'friday_one';
        case 6:
            return 'saturday_one';
        case 7:
        case 0:
            return 'sunday_one';
        default:
            throw Error('Wrong day');
    }
};

export const weekNumber = (date: Date): number => {
    const curDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 1, 0, 0);
    const startDate = weekMonday(new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0));
    const days = (curDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    return Math.ceil(days / 7);
};

export const yearWeekNumber = (date: Date): number => {
    const curDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 1, 0, 0);
    const startDate = new Date(date.getFullYear(), 0, 1, 0, 0, 0);
    const days = (curDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000);
    return Math.ceil(days / 7);
};

export const weekMonday = (date: Date) => {
    const result = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    result.setDate(date.getDate() - (date.getDay() !== 0 ? date.getDay() - 1 : 6));
    return result;
};

export const weekSunday = (date: Date) => {
    const result = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0);
    if (date.getDay() !== 0) {
        result.setDate(date.getDate() + (7 - date.getDay()));
    }
    return result;
};

export const monthFirstDate = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0);
};

export const monthLastDate = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0, 0, 0, 0);
};

export const month = (num: number): string => {
    switch (num) {
        case 0:
            return 'january';
        case 1:
            return 'february';
        case 2:
            return 'march';
        case 3:
            return 'april';
        case 4:
            return 'may';
        case 5:
            return 'june';
        case 6:
            return 'july';
        case 7:
            return 'august';
        case 8:
            return 'september';
        case 9:
            return 'october';
        case 10:
            return 'november';
        case 11:
            return 'december';
        default:
            throw Error('Wrong month: ' + String(num));
    }
};

export const addMonths = (date: Date, months: number | undefined = 1): Date => {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
};

export const addDays = (date: Date, days: number | undefined = 1): Date => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
};

export const addMinutes = (date: Date, minutes: number | undefined = 1): Date => {
    return moment(date).add(minutes, 'm').toDate();
};

export const getTime = (date: Date): number => {
    return moment(date).toDate().getTime();
};

export const dateMin = (date1: Date, date2: Date) => {
    return date1.getTime() < date2.getTime() ? date1 : date2;
};

export const dateMax = (date1: Date, date2: Date) => {
    return date1.getTime() > date2.getTime() ? date1 : date2;
};
