import { User } from 'apis/types';
import { get as getLoggedUser } from 'apis/user';
import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { StateType } from 'store/reducers';
import AppContext, { AppContextDataType } from '.';

const AppProvider: FC<PropsWithChildren> = ({ children }) => {
    const { accessToken } = useSelector((state: StateType) => state.auth);
    const [loggedUser, setLoggedUser] = useState<User | undefined>();
    const [openInviteDialog, setOpenInviteDialog] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (accessToken) {
            getLoggedUser(accessToken)
                .then((loggedUser) => setLoggedUser(loggedUser))
                .catch((error) => {
                    console.error(error);
                    setLoggedUser(undefined);
                });
        } else {
            setLoggedUser(undefined);
        }
    }, [accessToken, location]);

    const providedData: AppContextDataType = useMemo(
        () => ({
            loggedUser,
            openInviteDialog,
            setOpenInviteDialog
        }),
        [loggedUser, openInviteDialog]
    );

    return <AppContext.Provider value={providedData}>{loggedUser ? children : <></>}</AppContext.Provider>;
};

export default AppProvider;
