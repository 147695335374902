import Renew from 'pages/beetalk/Renew';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { StateType } from 'store/reducers';
import { WhitelabellingContext } from '.';

export const WhitelabelledRenew: React.FC = () => {
    const whitelabellingContext = useContext(WhitelabellingContext);
    const { agendaUuid } = useSelector((state: StateType) => state.auth);

    if (whitelabellingContext.paymentsPageUrl) {
        window.location.href = `${whitelabellingContext.paymentsPageUrl}?agendaUuid=${agendaUuid as string}`;
        return null;
    } else {
        return <Renew />;
    }
};
